<div class="header">
    <h2>Please Confirm Deletion</h2>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon color="warn">close</mat-icon>
    </button>
</div>

<div style="padding: 10px;">
    <div style="display: flex; flex-flow: row wrap; justify-content: space-between">
        <div style="display: flex; flex-flow: column wrap;">
            <h3 style="color: grey;"> Project </h3>
            <p>{{data[0].projectNo}} - {{data[0].description}}</p>
        </div>
        <div style="display: flex; flex-flow: column wrap">
            <h3 style="color: grey;"> Cost Code </h3>
            <p>{{data[1].costCode}} - {{data[1].description}}</p>
        </div>
    </div>
    <br>
    <div style="display: flex; flex-flow: row wrap; justify-content: space-between">
        <div *ngFor="let item of data | slice:2:9; let dateIndex = index" style="display: flex; flex-flow: column wrap; text-align: center;">
            <b style="color: grey; font-size: 12px;"> {{weekdays[dateIndex]}}</b>
            <b style="color: grey; font-size: 11px;"> {{currentWeek[dateIndex] | date: 'MM/dd'}}</b>
            <b>{{data[dateIndex + 2].hours | number : '1.1-1'}}</b>
        </div>
    </div>
    <br>
    <div style="display: flex; flex-flow: row wrap; justify-content: space-between">
        <b style="color: grey; font-size: 12px;"> Total(hrs)</b>
        <b> {{data[9].rowTotal | number : '1.1-1'}}</b>
    </div>
    <br>
    <div [align]="'end'">
        <button mat-stroked-button [mat-dialog-close]="false" color="primary">Cancel</button>
        <button mat-stroked-button (click)="HanldeDeletion()" cdkFocusInitial color="warn">
            DELETE
        </button>
    </div>
</div>