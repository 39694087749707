import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { addDays, format } from 'date-fns';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit {
  @Input('startDate') startDate: string;
  dateCtrl = new UntypedFormControl();
  @Output() dateChangeEvent = new EventEmitter();
  date: Date = new Date();

  constructor() { }

  ngOnInit() {
    if (this.startDate !== undefined && this.startDate !== null && this.startDate !== '') {
      this.date = new Date(this.startDate);
    }
    this.setDate(this.date);
  }

  updateDate(days: number): void {
    if (!this.dateCtrl.invalid) {
      this.date = (addDays(new Date(this.date), days));
      this.setDate(this.date);
      this.emitDate(this.date);
    }
  }

  onDateChangeEvent(): void {
    if (!this.dateCtrl.invalid) {
      this.date = this.dateCtrl.value;
      this.emitDate(this.date);
    }
  }

  setDate(date: Date): void {
    this.dateCtrl.setValue(date);
  }

  emitDate(date: Date): void {
    this.dateChangeEvent.emit(format(date, 'MM/dd/yyyy'));
  }
}
