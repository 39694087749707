import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimecardService {
  apiUrl = environment.apiUrl;
  timecardEndpoint = this.apiUrl + '/Timecards';
  projectionsEndpoint = this.apiUrl + '/TimecardProjections';

  constructor(private _httpClient: HttpClient) { }

  getTimecard(timecardId: number, query: string): Observable<any> {
    return this._httpClient.get<any>(`${this.timecardEndpoint}(${timecardId})?${query}`);
  }

  getTimecards(query: string): Observable<any[]> {
    return this._httpClient.get<any[]>(`${this.timecardEndpoint}?${query}`);
  }

  updateTimecard(timecardId: number, patch: any): Observable<any> {
    return this._httpClient.patch<any>(`${this.timecardEndpoint}(${timecardId})`, patch);
  }

  createTimecard(timecard: any): Observable<any> {
    return this._httpClient.post<any>(`${this.timecardEndpoint}`, timecard);
  }

  deleteTimecard(timecardId: number): Observable<any> {
    return this._httpClient.delete<any>(`${this.timecardEndpoint}(${timecardId})`);
  }

  approveTimecard(timecardId: number): Observable<any> {
    return this._httpClient.post<any>(`${this.timecardEndpoint}(${timecardId})/Approve`, {});
  }

  approveRange(params: any): Observable<any> {
    return this._httpClient.post<any>(`${this.timecardEndpoint}/Approve`, params);
  }

  reviewRange(params: any): Observable<any> {
    return this._httpClient.post<any>(`${this.timecardEndpoint}/Review`, params);
  }

  exportTimecards(params: any): Observable<any> {
    return this._httpClient.post(`${this.timecardEndpoint}/Export`, params, {responseType: 'blob', headers: new HttpHeaders().append('Content-Type', 'application/json') });
  }

  sortEmployees(employees: any[], key: string): any[] {
    return employees.sort(function (a, b) {
      const nameA = a[key].toUpperCase(); // ignore upper and lowercase
      const nameB = b[key].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }

  sortTimecardsByDate(timecards: any[], desc: boolean): any[] {
    return timecards.sort(function (a, b) {
      const dateA = new Date(a.Date).getTime();
      const dateB = new Date(b.Date).getTime();
      if (desc) {
        return dateA < dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order;
      } else {
        return dateA < dateB ? -1 : 11; // ? -1 : 1 for ascending/increasing order;
      }
    });
  }

  getProjectionTimecard(timecardId: number, query: string): Observable<any> {
    return this._httpClient.get<any>(`${this.projectionsEndpoint}(${timecardId})?${query}`);
  }

  getProjectionTimecards(query: string): Observable<any[]> {
    return this._httpClient.get<any[]>(`${this.projectionsEndpoint}?${query}`);
  }

  updateProjectionTimecard(timecardId: number, patch: any): Observable<any> {
    return this._httpClient.patch<any>(`${this.projectionsEndpoint}(${timecardId})`, patch);
  }

  createProjectionTimecard(timecard: any): Observable<any> {
    return this._httpClient.post<any>(`${this.projectionsEndpoint}`, timecard);
  }

  deleteProjectionTimecard(timecardId: number): Observable<any> {
    return this._httpClient.delete<any>(`${this.projectionsEndpoint}(${timecardId})`);
  }

}

