import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanySettings } from '../../security/shared/models/company-settings.model';
import { DateService } from '../../shared/services/date.service';
import { format, getWeek, isAfter, isSameWeek } from 'date-fns';
import { DailysSetting } from './settings.model';


@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private companySettings: CompanySettings;

  apiUrl = environment.apiUrl;
  settingEndoint = this.apiUrl + '/Settings';
  companyEndoint = this.apiUrl + '/Companies';

  constructor(private _httpClient: HttpClient, private _dateService: DateService) {
      this.Init();
  }

  updateSetting(settingId: number, patch: any): Observable<any> {
    return this._httpClient.patch<any>(`${this.settingEndoint}(${settingId})`, patch);
  }

  public Init() {
    this._httpClient.get<CompanySettings>(`${this.apiUrl}/Companies?$select=Id,Name&$expand=Settings`)
      .subscribe((data: any) => {
        this.companySettings = data?.value[0]?.Settings;
      }, error => {
        console.log(error);
      });
  }

  public getCompanySettings(): CompanySettings {
    return this.companySettings;
  }

  getSettings(companyId: number): Observable<any> {
    return this._httpClient.get<any>(`${this.companyEndoint}(${companyId})/Settings`);
  }

  getSettings1(): Observable<any> {
    return this._httpClient.get<any>(`${this.settingEndoint}`);
  }

  isApprovalPastDue(currentDate: Date, dueDay: string, dueTime: string): boolean {
    const approvalWeek = this._dateService.getWeekDates(currentDate);
    const approvalDueWeek = this._dateService.getNextWeekDates(approvalWeek);
    const index = this._dateService.getWeekDayIndex(dueDay)
    const entryDueDate = `${format(new Date(approvalDueWeek[index]), 'yyyy-MM-dd')}T${dueTime}:00`;

    if (isSameWeek(new Date(), currentDate) || getWeek(new Date()) - getWeek(currentDate) < 1) { // current or future week
      return false; // can approve
    } else if (getWeek(new Date()) - getWeek(currentDate) === 1) { // last week
      if (isAfter(new Date(), new Date(entryDueDate))) {
        return true
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  getDailysSettings(selectedDate: Date, companySettings): DailysSetting {
    const settings = new DailysSetting();
    settings.entryDueDate = this.getDueDate(selectedDate, companySettings.TimecardEntryDueDay, companySettings.TimecardEntryDueTime);
    settings.isEntryPastDue = this.isPastDue(selectedDate, settings.entryDueDate);
    settings.entryDueDay = `${companySettings.TimecardEntryDueDay} ${companySettings.TimecardEntryDueTime}`;
    settings.approvalDueDate = this.getDueDate(selectedDate, companySettings.TimecardApprovalDueDay, companySettings.TimecardApprovalDueTime);
    settings.isApprovalPastDue = this.isPastDue(selectedDate, settings.approvalDueDate);
    settings.approvalDueDay = `${companySettings.TimecardApprovalDueDay} ${companySettings.TimecardApprovalDueTime}`;
    settings.isDiaryRequired = companySettings.IsDiaryEntryRequired;
    settings.isJhaRequired = companySettings.IsJhaEntryRequired;
    settings.isTimecardEntryAllowed = companySettings.IsTimecardEntryAllowed;
    settings.maxHoursPerDay = companySettings.TimecardMaxHoursPerDay;
    settings.maxHoursPerWeek = companySettings.TimecardMaxHoursPerWeek;
    settings.lateDaysAllowed = companySettings.TimecardEntryLateDaysAllowed;
    settings.averageManHourCost = companySettings.AverageManHourCost;
    settings.requireToDateSummary = companySettings.RequireToDateSummary;

    return settings;
  }

  private getDueDate(selectedDate: Date, dayOfWeek: string, timeOfDay: string): Date {
    const actionWeek = this._dateService.getWeekDates(selectedDate);
    const actionDueWeek = this._dateService.getNextWeekDates(actionWeek);
    const index = this._dateService.getWeekDayIndex(dayOfWeek)
    const entryDueDate = new Date(`${format(new Date(actionDueWeek[index]), 'yyyy-MM-dd')}T${timeOfDay}:00`);
    return entryDueDate;
  }

  private isPastDue(currentDate: Date, dueDate: Date): boolean {
    if (isSameWeek(new Date(), currentDate) || getWeek(new Date()) - getWeek(currentDate) < 1) { // current or future week
      return false; // can approve
    } else if (getWeek(new Date()) - getWeek(currentDate) === 1) { // last week
      if (isAfter(new Date(), new Date(dueDate))) {
        return true
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

}
