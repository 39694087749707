// =============================
// GroundWorks
// Ragle Inc.
// =============================

export class User {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor(id?: string, userName?: string, fullName?:
        string, email?: string, jobTitle?: string, phoneNumber?:
        string, roles?: string[], employeeId?: string, companyId?:
        string, divisionId?: string) {
        this.id = id;
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.jobTitle = jobTitle;
        this.phoneNumber = phoneNumber;
        this.roles = roles;
        this.employeeId = employeeId;
        this.companyId = companyId;
        this.divisionId = divisionId;
    }

    get friendlyName(): string {
        const name = this.fullName || this.userName;

        return name;
    }


    public id: string;
    public userName: string;
    public fullName: string;
    public email: string;
    public jobTitle: string;
    public phoneNumber: string;
    public isEnabled: boolean;
    public isLockedOut: boolean;
    public roles: string[];
    public employeeId: string;
    public companyId: string;
    public divisionId: string;
    name: string;
    number: string;
    firstName: string;
    middleInitial: string;
    lastName: string;
    gender: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    payType: string;
    EarnTypeNo: string;
    discipline: string;
    hiredOn = Date;
    terminatedOn = Date;
    lastWorkedOn = Date;
    rehiredOn = Date;
    lastModifiedBy: string;
    lastModifiedOn: Date;
}
