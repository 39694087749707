import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ProjectsService } from '../../../../projects/projects.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProjectsService } from '../../../projects/projects.service';


@Component({
  selector: 'app-quantity-dialog',
  templateUrl: './quantity-dialog.component.html',
  styleUrls: ['./quantity-dialog.component.css']
})
export class QuantityDialogComponent implements OnInit {
  newQtyForm: UntypedFormGroup;
  costCodeSelected: boolean = false;
  costClass: string = '1';
  selectedCostCode: any

  filteredCostCodes: Observable<any[]>;
  costCodesCtrl = new UntypedFormControl();
  costCodes: any[];

  constructor(private _projectService: ProjectsService, 
    public dialogRef: MatDialogRef<QuantityDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.initializeForm();
    this.getProjectCostCode();
    this.selectedCostCode = `${this.data.quantity.CostCodeNo} - ${this.data.quantity.CostCodeDescription}`
  }

  private getProjectCostCode() {
    const query = `$select=Id,CostCodeNo,Description,UnitOfMeasure,OriginalQuantity&$filter=Status eq 'Active'&$orderby=CostCodeNo`;
    this._projectService.getProjectCostCodes(this.data.quantity.ProjectId, query).subscribe(result => {
      this.costCodes = result['value'];
      this.loadCostCodessAutoComplete(this.costCodes);
    });
  }

  private loadCostCodessAutoComplete(costcodes: any[]) {
    this.filteredCostCodes = this.costCodesCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? this._filteredCostCodes(value) : costcodes.slice())
      );
  }

  private _filteredCostCodes(value: string): any[] {
    console.log(value);
    const filterValue = value.toLowerCase();

    return this.costCodes.filter(
      costCode => costCode.CostCodeNo.toLowerCase().includes(filterValue) || 
      costCode.Description.toLowerCase().includes(filterValue));
  }

  costCodeChanged(costCode: any): void {
    if (costCode) {
      this.data.quantity.Unit = costCode.UnitOfMeasure;
      this.data.quantity.EstQuantity = costCode.OriginalQuantity;
      this.data.quantity.CostCodeNo = costCode.CostCodeNo;
      this.data.quantity.CostCodeDescription = costCode.Description;
    }
  }

  getCostCodeOptionText(costCode: any) {
    return costCode && costCode.CostCodeNo ? `${costCode.CostCodeNo} - ${costCode.Description}` : '';
  }

  private initializeForm(): void {
    this.newQtyForm = new UntypedFormGroup({
      qtyDate: new UntypedFormControl(null, Validators.compose([Validators.required])),
      amount: new UntypedFormControl(null, Validators.compose([Validators.required])),
    });
  }

  addCostCode(costCode: any): void {
    console.log(costCode);
    if ( costCode !== undefined || costCode !== null ) {
      this.data.quantity.costCodeNo = costCode.costCodeNo;
      this.data.quantity.costCodeDescription = costCode.description;
      this.data.quantity.estQuantity = costCode.estQty;
      this.data.quantity.estUnits = costCode.estUnits;
      this.costCodeSelected = true;
    }
  }
  addCrew(crew: any): void {
    if ( crew !== undefined || crew !== null ) {
      this.data.quantity.crew = crew;
    }
  }

  formatCostCode(quantity: any){
    console.log(quantity);
    
    return `${quantity.CostCodeNo} - ${quantity.CostCodeDescription}`
  }

  onSelect(event: Event): void {
    console.log(event);
  }
}
