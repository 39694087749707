
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Permission, PermissionValues } from '../../shared/models/permission.model';
import { Role } from '../../shared/models/role.model';
import { UserEdit } from '../../shared/models/user-edit.model';
import { User } from '../../shared/models/user.model';
import { AccountEndpoint } from '../../shared/services/account-endpoint.service';
import { AuthService } from '../../shared/services/auth.service';

export type RolesChangedOperation = 'add' | 'delete' | 'modify';
export interface RolesChangedEventArg { roles: Role[] | string[]; operation: RolesChangedOperation; }

@Injectable()
export class UserService {
    public static readonly roleAddedOperation: RolesChangedOperation = 'add';
    public static readonly roleDeletedOperation: RolesChangedOperation = 'delete';
    public static readonly roleModifiedOperation: RolesChangedOperation = 'modify';

    private _rolesChanged = new Subject<RolesChangedEventArg>();

    apiUrlV1 = environment.apiUrl;
    public userEndpoint = this.apiUrlV1 + '/Users';

    constructor(public httpClient: HttpClient, private authService: AuthService, private accountEndpoint: AccountEndpoint) { }

    getUser(userId: string, query: string) {
        return this.httpClient.get<any[]>(`${this.userEndpoint}('${userId}')?${query}`);
    }

    getCurrentUser(query: string) {
        return this.httpClient.get<any[]>(`${this.userEndpoint}/CurrentUser()?${query}`);
    }

    getUsers(query: string) {
        return this.httpClient.get<any[]>(`${this.userEndpoint}?${query}`);
    }

    enableUser(userId: string) {
        return this.httpClient.post<any[]>(`${this.userEndpoint}('${userId}')/Enable`, null);
    }

    updateUser2(userId: string, patch: any) {
        return this.httpClient.patch<any[]>(`${this.userEndpoint}('${userId}')`,patch);
    }

    disableUser(userId: string) {
        return this.httpClient.post<any[]>(`${this.userEndpoint}('${userId}')/Disable`, null);
    }

    unBlockUser(userId: string) {
        return this.httpClient.post<any[]>(`${this.userEndpoint}('${userId}')/UnBlock`, null);
    }

    createLogin(userId: string) {
        return this.httpClient.post<any[]>(`${this.userEndpoint}('${userId}')/CreateLogin`, null);
    }

    resetPassword(userId: string, data: any) {
        return this.httpClient.post<any[]>(`${this.userEndpoint}('${userId}')/ResetPassword`, data);
    }

    changePassword(userId: string, data: any) {
        return this.httpClient.post<any[]>(`${this.userEndpoint}('${userId}')/ChangePassword`, data);
    }

    sortEmployees(employees: any[], key: string): any[] {
        return employees.sort(function (a, b) {
          const nameA = a[key].toUpperCase(); // ignore upper and lowercase
          const nameB = b[key].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      }
    // ==============================================


    getUserByEmployeeId(employeeId?: string) {
        return this.accountEndpoint.getUserByEmployeeIdEndpoint<User>(employeeId);
    }

    getUserAndRoles(userId?: string) {

        return forkJoin(
            this.accountEndpoint.getUserEndpoint<User>(userId),
            this.accountEndpoint.getRolesEndpoint<Role[]>()
            );
    }

    getUsersAndRoles(page?: number, pageSize?: number) {

        return forkJoin(
            this.accountEndpoint.getUsersEndpoint<User[]>(page, pageSize),
            this.accountEndpoint.getRolesEndpoint<Role[]>());
    }

    updateUser(user: UserEdit) {
        if (user.id) {
            return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
        }
        else {
            return this.accountEndpoint.getUserByUserNameEndpoint<User>(user.userName)
                .pipe(mergeMap(foundUser => {
                    user.id = foundUser.id;
                    return this.accountEndpoint.getUpdateUserEndpoint(user, user.id);
                }));
        }
    }

    newUser(user: UserEdit) {
        return this.accountEndpoint.getNewUserEndpoint<User>(user);
    }

    getUserPreferences() {
        return this.accountEndpoint.getUserPreferencesEndpoint<string>();
    }

    updateUserPreferences(configuration: string) {
        return this.accountEndpoint.getUpdateUserPreferencesEndpoint(configuration);
    }

    deleteUser(userOrUserId: string | User): Observable<User> {
        if (typeof userOrUserId === 'string' || userOrUserId instanceof String) {
            return this.accountEndpoint.getDeleteUserEndpoint<User>(<string>userOrUserId)

                .pipe(tap(data => this.onRolesUserCountChanged(data.roles)));
        }
        else {
            if (userOrUserId.id) {
                return this.deleteUser(userOrUserId.id);
            }
            else {
                return this.accountEndpoint.getUserByUserNameEndpoint<User>(userOrUserId.userName)
                    .pipe(mergeMap(user => this.deleteUser(user.id)));
            }
        }
    }

    unblockUser(userId: string) {
        return this.accountEndpoint.getUnblockUserEndpoint(userId);
    }

    userHasPermission(permissionValue: PermissionValues): boolean {
        return this.permissions.some(p => p === permissionValue);
    }

    userHasRoles(roles: string[]): boolean {
       return this.currentUser.roles.some((r) => roles.indexOf(r) !== -1);
    }

    userHasPermissions(permissionValues: PermissionValues[]): boolean {
        return this.permissions.some(p => permissionValues.indexOf(p) >= 0);
    }

    refreshLoggedInUser() {
        return this.authService.refreshLogin();
    }

    getRoles(page?: number, pageSize?: number) {
        return this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize);
    }

    getRolesAndPermissions(page?: number, pageSize?: number) {
        return forkJoin(
            this.accountEndpoint.getRolesEndpoint<Role[]>(page, pageSize),
            this.accountEndpoint.getPermissionsEndpoint<Permission[]>());
    }

    updateRole(role: Role) {
        if (role.id) {
            return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id)
                .pipe(tap(data => this.onRolesChanged([role], UserService.roleModifiedOperation)));
        }
        else {
            return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(role.name)
                .pipe(mergeMap(foundRole => {
                    role.id = foundRole.id;
                    return this.accountEndpoint.getUpdateRoleEndpoint(role, role.id);
                }),

                    tap(data => this.onRolesChanged([role], UserService.roleModifiedOperation))
                );
        }
    }

    newRole(role: Role) {
        return this.accountEndpoint.getNewRoleEndpoint<Role>(role)
            .pipe(tap(data => this.onRolesChanged([role], UserService.roleAddedOperation)));
    }

    deleteRole(roleOrRoleId: string | Role): Observable<Role> {

        if (typeof roleOrRoleId === 'string' || roleOrRoleId instanceof String) {
            return this.accountEndpoint.getDeleteRoleEndpoint<Role>(<string>roleOrRoleId)
                .pipe(tap(data => this.onRolesChanged([data], UserService.roleDeletedOperation)));
        }
        else {

            if (roleOrRoleId.id) {
                return this.deleteRole(roleOrRoleId.id);
            }
            else {
                return this.accountEndpoint.getRoleByRoleNameEndpoint<Role>(roleOrRoleId.name)
                    .pipe(mergeMap(role => this.deleteRole(role.id)));
            }
        }
    }

    getPermissions() {
        return this.accountEndpoint.getPermissionsEndpoint<Permission[]>();
    }

    private onRolesChanged(roles: Role[] | string[], op: RolesChangedOperation) {
        this._rolesChanged.next({ roles: roles, operation: op });
    }

    onRolesUserCountChanged(roles: Role[] | string[]) {
        return this.onRolesChanged(roles, UserService.roleModifiedOperation);
    }

    getRolesChangedEvent(): Observable<RolesChangedEventArg> {
        return this._rolesChanged.asObservable();
    }

    get permissions(): PermissionValues[] {
        return this.authService.userPermissions;
    }

    get currentUser() {
        return this.authService.currentUser;
    }
}
