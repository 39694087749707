<h2 mat-dialog-title>New Credit Card Details</h2>
<br>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>First Name</mat-label>
                <input matInput type="text" formControlName="cardHolderFirstName">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" formControlName="cardHolderLastName">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>Account Number(Last 4)</mat-label>
                <input matInput type="text" formControlName="accountNumber">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>Credit Line(optional)</mat-label>
                <input matInput type="number" formControlName="creditLine">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>Issued On(optional)</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="issuedOn">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="49">
                <mat-label>Expires On(optional)</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="expiresOn">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" [mat-dialog-close]="null">Cancel</button>
    <button mat-stroked-button color="primary" [mat-dialog-close]="formGroup.value" [disabled]="!formGroup.valid">Save</button>
</mat-dialog-actions>