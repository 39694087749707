import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-credit-card-dialog',
  templateUrl: './create-credit-card-dialog.component.html',
  styleUrls: ['./create-credit-card-dialog.component.scss']
})
export class CreateCreditCardDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  result = {}

  constructor(public dialogRef: MatDialogRef<CreateCreditCardDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder) { 

  }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('app-confirm-dialog');
    this.buildForm();
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      'cardHolderFirstName': new UntypedFormControl(this.data?.FirstName,  [Validators.required]),
      'cardHolderLastName': new UntypedFormControl(this.data?.LastName,  [Validators.required]),
      'accountNumber': new UntypedFormControl('',  [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      'creditLine': new UntypedFormControl(),
      'issuedOn': new UntypedFormControl(),
      'expiresOn': new UntypedFormControl()
    });
  }
}
