import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { User } from '../../../../security/shared/models/user.model';
import { AuthService } from '../../../../security/shared/services/auth.service';
import { AccountService } from '../../../../security/shared/services/account.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  user: User;
  public userImage = '../assets/img/users/default-user.jpg';
  constructor(private authService: AuthService, public router: Router, private _accountService: AccountService) { }

  ngOnInit() {
    this.user = this._accountService.currentUser;
  }

  logout() {
    this.authService.logout();
    this.authService.redirectLogoutUser();
}

}
