import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../settings/app.settings';
import { Settings } from '../../../../settings/app.settings.model';
import { MenuService } from '../menu.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { AccountService } from '../../../../security/shared/services/account.service';
import { PermissionValues } from '../../../../security/shared/models/permission.model';


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<any>;
  public settings: Settings;
  @ViewChild(MatMenuTrigger, {static: true}) trigger: MatMenuTrigger;
  constructor(public appSettings: AppSettings, public menuService: MenuService, public router: Router, private _accountService: AccountService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();
    // this.menuItems = this.menuItems.filter(item => item.parentId === this.menuParentId);
    this.menuItems = this.menuItems.filter(item => item.parentId === this.menuParentId && this._accountService.userHasPermissions(item.permissions));
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          const mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        }
        else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
  }

  canAccessUrl(permission: PermissionValues) {
    return true;
  }


}
