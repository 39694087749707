<h2 mat-dialog-title>Select File...</h2>
<br>
<mat-dialog-content>
    <input #fileCtrl type="file" id="fileSelector" [accept]="data.accept" (change)="onSelectedFileChange($event)"
        class="file-input" />
    <div fxLayout="row" class="file-upload" style="width: 100%;">
        <div fxFlex="90" fxLayoutAlign="start center">
            {{fileCtrl?.files[0]?.name || "No file uploaded yet."}}
        </div>

        <div fxLayoutAlign="end end">
            <button mat-mini-fab color="" class="upload-btn" (click)="fileCtrl.click()">
                <mat-icon>attach_file</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]="null">CANCEL</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="fileCtrl.files"
        [disabled]="fileCtrl.files.length === 0">UPLOAD</button>
</mat-dialog-actions>