import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class CompanyService {
  apiUrl = environment.apiUrl;
  companyEndpoint = this.apiUrl + '/Companies';

  constructor( private _httpClient: HttpClient) { }

  getCompany(query: string, companyId: number): Observable<any[]> {
    return this._httpClient.get<any[]>(`${this.companyEndpoint}(${companyId})?${query}`);
  }

  getCompanies(query: string): Observable<any[]> {
    return this._httpClient.get<any[]>(`${this.companyEndpoint}?${query}`);
  }
}
