<div *ngIf="weekDates" fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button (click)="updateWeek(-1)">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <div>
        <mat-form-field appearance="outline" style="width: 220px;" class="no-margin-bottom">
            <mat-label style="color: blue;">Week range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date "
                    (dateChange)="onDateChangeEvent(range)">>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls['start'].hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls['end'].hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
    </div>
    <button mat-icon-button (click)="updateWeek(1)">
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>