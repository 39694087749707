
<div  fxLayout="column" fxLayoutAlign="center center" class="user-block transition-2"> 
  <div fxLayout="row" fxLayoutAlign="space-between center center" class="user-info-wrapper">
    <div>
        <img [src]=logoUrl class="transition-2" alt="user-image"class="">
    </div>
      <div *ngIf="company" class="user-info">
          <p class="name">{{company.Name}}</p>
          <p class="position">{{company.City}}, {{company.State}}<br> <small *ngIf="division"class="muted-text">{{division.Name}}</small></p>
      </div>
  </div>
</div>