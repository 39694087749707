import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../../company/company.service';
import { UserService } from '../../../../security/users/services/user.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {

  company;
  division;
  logoUrl = '';

  constructor(private _companyService: CompanyService, private userService: UserService) { }

  ngOnInit() {
    this.LoadCompanyAndDivision();
  }

  private LoadCompanyAndDivision() {
    this._companyService.getCompanies(`$select=Id,Name,ShortName,Description,AddressLine1,AddressLine2,City,State`).subscribe( result => {
       this.company = result['value'][0];
       this.setLogoUrl();
    })

    this.userService.getCurrentUser('$select=Name&$expand=Division($select=Name)').subscribe( result => {
      this.division = result['Division'];
    })
  }

  private setLogoUrl() {
    if(this.company.Name === 'Ragle Inc') {
      this.logoUrl = 'assets/img/logo_ragle.png';
    }
    else if( this.company.Name === 'Select Maintenance LLC') {
      this.logoUrl = 'assets/img/logo_select.png';
    }
  }
}
