// =============================
// GroundWorks
// Ragle Inc.
// =============================

export type PermissionNames =
    'Create Users' | 'Read Users' | 'Update Users' | 'Delete Users' | 'Export Users' | 'Approve Users' |
    'Create Roles' | 'Read Roles' | 'Update Roles' | 'Delete Roles' | 'Export Roles' | 'Approve Roles' |
    'Create Projects' | 'Read Projects' | 'Update Projects' | 'Delete Projects' | 'Export Projects' | 'Approve Projects' |
    'Create Employees' | 'Read Employees' | 'Update Employees' | 'Delete Employees' | 'Export Employees' | 'Approve Employees' |
    'Create Timecards' | 'Read Timecards' | 'Update Timecards' | 'Delete Timecards' | 'Export Timecards' | 'Approve Timecards' |
    'Create Jhas' | 'Read Jhas' | 'Update Jhas' | 'Delete Jhas' | 'Export Jhas' | 'Approve Jhas' |
    'Create Diaries' | 'Read Diaries' | 'Update Diaries' | 'Delete Diaries' | 'Export Diaries' | 'Approve Diaries' |
    'Create Quantities' | 'Read Quantities' | 'Update Quantities' | 'Delete Quantities' | 'Export Quantities' | 'Approve Quantities' |
    'Create Receipts' | 'Read Receipts' | 'Update Receipts' | 'Delete Receipts' | 'Export Receipts' | 'Approve Receipts' |
    'Create Change Orders' | 'Read Change Orders' | 'Update Change Orders' | 'Delete Change Orders' | 'Export Change Orders' | 'Approve Change Orders' |
    'Create Exec Reports' | 'Read Exec Reports' | 'Update Exec Reports' | 'Delete Exec Reports' | 'Export Exec Reports' | 'Approve Exec Reports' |
    'Create Crews' | 'Read Crews' | 'Update Crews' | 'Delete Crews' | 'Export Crews' | 'Approve Crews' |
    'Create Settings' | 'Read Settings' | 'Update Settings' | 'Delete Settings' | 'Export Settings' | 'Approve Settings' ;

export type PermissionValues =
    'users.create' | 'users.read' | 'users.update' | 'users.delete' | 'users.export' | 'users.approve' |
    'roles.create' | 'roles.read' | 'roles.update' | 'roles.delete' | 'roles.export' | 'roles.approve' |
    'projects.create' | 'projects.read' | 'projects.update' | 'projects.delete' | 'projects.export' | 'projects.approve' |
    'employees.create' | 'employees.read' | 'employees.update' | 'employees.delete' | 'employees.export' | 'employees.approve' |
    'timecards.create' | 'timecards.read' | 'timecards.update' | 'timecards.delete' | 'timecards.export' | 'timecards.approve' |
    'jhas.create' | 'jhas.read' | 'jhas.update' | 'jhas.delete' | 'jhas.export' | 'jhas.approve' |
    'diaries.create' | 'diaries.read' | 'diaries.update' | 'diaries.delete' | 'diaries.export' | 'diaries.approve' |
    'quantities.create' | 'quantities.read' | 'quantities.update' | 'quantities.delete' | 'quantities.export' | 'quantities.approve' |
    'receipts.create' | 'receipts.read' | 'receipts.update' | 'receipts.delete' | 'receipts.export' | 'receipts.approve' |
    'changeorders.create' | 'changeorders.read' | 'changeorders.update' | 'changeorders.delete' | 'changeorders.export' | 'changeorders.approve' |
    'execreports.create' | 'execreports.read' | 'execreports.update' | 'execreports.delete' | 'execreports.export' | 'execreports.approve' |
    'crews.create' | 'crews.read' | 'crews.update' | 'crews.delete' | 'crews.export' | 'crews.approve' |
    'settings.create' | 'settings.read' | 'settings.update' | 'settings.delete' | 'settings.export' |  'settings.approve' ;

export class Permission {

    // Users
    public static readonly createUsersPermission: PermissionValues = 'users.create';
    public static readonly readUsersPermission: PermissionValues = 'users.read';
    public static readonly updateUsersPermission: PermissionValues = 'users.update';
    public static readonly deleteUsersPermission: PermissionValues = 'users.delete';
    public static readonly exportUsersPermission: PermissionValues = 'users.export';
    public static readonly approveUsersPermission: PermissionValues = 'users.approve';

    // Roles
    public static readonly createRolesPermission: PermissionValues = 'roles.create';
    public static readonly readRolesPermission: PermissionValues = 'roles.read';
    public static readonly updateRolesPermission: PermissionValues = 'roles.update';
    public static readonly deleteRolesPermission: PermissionValues = 'roles.delete';
    public static readonly exportRolesPermission: PermissionValues = 'roles.export';
    public static readonly approveRolesPermission: PermissionValues = 'roles.approve';

    // Projects
    public static readonly createProjectsPermission: PermissionValues = 'projects.create';
    public static readonly readProjectsPermission: PermissionValues = 'projects.read';
    public static readonly updateProjectsPermission: PermissionValues = 'projects.update';
    public static readonly deleteProjectsPermission: PermissionValues = 'projects.delete';
    public static readonly exportProjectsPermission: PermissionValues = 'projects.export';
    public static readonly approveProjectsPermission: PermissionValues = 'projects.approve';

    // Employees
    public static readonly createEmployeesPermission: PermissionValues = 'employees.create';
    public static readonly readEmployeesPermission: PermissionValues = 'employees.read';
    public static readonly updateEmployeesPermission: PermissionValues = 'employees.update';
    public static readonly deleteEmployeesPermission: PermissionValues = 'employees.delete';
    public static readonly exportEmployeesPermission: PermissionValues = 'employees.export';
    public static readonly approveEmployeesPermission: PermissionValues = 'employees.approve';

    // Timecards
    public static readonly createTimecardsPermission: PermissionValues = 'timecards.create';
    public static readonly readTimecardsPermission: PermissionValues = 'timecards.read';
    public static readonly updateTimecardsPermission: PermissionValues = 'timecards.update';
    public static readonly deleteTimecardsPermission: PermissionValues = 'timecards.delete';
    public static readonly exportTimecardsPermission: PermissionValues = 'timecards.export';
    public static readonly approveTimecardsPermission: PermissionValues = 'timecards.approve';

    // Jhas
    public static readonly createJhasPermission: PermissionValues = 'jhas.create';
    public static readonly readJhasPermission: PermissionValues = 'jhas.read';
    public static readonly updateJhasPermission: PermissionValues = 'jhas.update';
    public static readonly deleteJhasPermission: PermissionValues = 'jhas.delete';
    public static readonly exportJhasPermission: PermissionValues = 'jhas.export';
    public static readonly approveJhasPermission: PermissionValues = 'jhas.approve';

    // Diaries
    public static readonly createDiariesPermission: PermissionValues = 'diaries.create';
    public static readonly readDiariesPermission: PermissionValues = 'diaries.read';
    public static readonly updateDiariesPermission: PermissionValues = 'diaries.update';
    public static readonly deleteDiariesPermission: PermissionValues = 'diaries.delete';
    public static readonly exportDiariesPermission: PermissionValues = 'diaries.export';
    public static readonly approveDiariesPermission: PermissionValues = 'diaries.approve';

    // Quantities
    public static readonly createQuantitiesPermission: PermissionValues = 'quantities.create';
    public static readonly readQuantitiesPermission: PermissionValues = 'quantities.read';
    public static readonly updateQuantitiesPermission: PermissionValues = 'quantities.update';
    public static readonly deleteQuantitiesPermission: PermissionValues = 'quantities.delete';
    public static readonly exportQuantitiesPermission: PermissionValues = 'quantities.export';
    public static readonly approveQuantitiesPermission: PermissionValues = 'quantities.approve';

    // Receipt
    public static readonly createReceiptsPermission: PermissionValues = 'receipts.create';
    public static readonly readReceiptsPermission: PermissionValues = 'receipts.read';
    public static readonly updateReceiptsPermission: PermissionValues = 'receipts.update';
    public static readonly deleteReceiptsPermission: PermissionValues = 'receipts.delete';
    public static readonly exportReceiptsPermission: PermissionValues = 'receipts.export';
    public static readonly approveReceiptsPermission: PermissionValues = 'receipts.approve';

    // Change Orders
    public static readonly createChangeOrdersPermission: PermissionValues = 'changeorders.create';
    public static readonly readChangeOrdersPermission: PermissionValues = 'changeorders.read';
    public static readonly updateChangeOrdersPermission: PermissionValues = 'changeorders.update';
    public static readonly deleteChangeOrdersPermission: PermissionValues = 'changeorders.delete';
    public static readonly exportChangeOrdersPermission: PermissionValues = 'changeorders.export';
    public static readonly approveChangeOrdersPermission: PermissionValues = 'changeorders.approve';

    // Executive Reports
    public static readonly createExecReportsPermission: PermissionValues = 'execreports.create';
    public static readonly readExecReportsPermission: PermissionValues = 'execreports.read';
    public static readonly updateExecReportsPermission: PermissionValues = 'execreports.update';
    public static readonly deleteExecReportsPermission: PermissionValues = 'execreports.delete';
    public static readonly exportExecReportsPermission: PermissionValues = 'execreports.export';
    public static readonly approveExecReportsPermission: PermissionValues = 'execreports.approve';

    public static readonly createCrewsPermission: PermissionValues = 'crews.create';
    public static readonly readCrewsPermission: PermissionValues = 'crews.read';
    public static readonly updateCrewsPermission: PermissionValues = 'crews.update';
    public static readonly deleteCrewsPermission: PermissionValues = 'crews.delete';
    public static readonly exportCrewsPermission: PermissionValues = 'crews.export';
    public static readonly approveCrewsPermission: PermissionValues = 'crews.export';

    public static readonly createSettingsPermission: PermissionValues = 'settings.create';
    public static readonly readSettingsPermission: PermissionValues = 'settings.read';
    public static readonly updateSettingsPermission: PermissionValues = 'settings.update';
    public static readonly deleteSettingsPermission: PermissionValues = 'settings.delete';
    public static readonly exportSettingsPermission: PermissionValues = 'settings.export';
    public static readonly approveSettingsPermission: PermissionValues = 'settings.export';
    constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }

    public name: PermissionNames;
    public value: PermissionValues;
    public groupName: string;
    public description: string;
}
