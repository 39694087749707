<table Id="dataTable" class="full-width" mat-table *ngIf="dataSource" #table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="AccountNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number </th>
        <td mat-cell *matCellDef="let row">*****{{row.AccountNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="CardHolderFirstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Name</th>
        <td mat-cell *matCellDef="let row">{{row.CardHolderFirstName}} {{row.CardHolderLastName}}</td>
    </ng-container>
    <ng-container matColumnDef="CreditLine">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Credit Line</th>
        <td mat-cell *matCellDef="let row"> {{row.CreditLine | currency}} </td>
    </ng-container>
    <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
    </ng-container>
    <ng-container matColumnDef="CardHolder">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Linked User</th>
        <td mat-cell *matCellDef="let row"> {{row.CardHolder?.Name}} </td>
    </ng-container>
    <ng-container matColumnDef="Imported">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imported</th>
        <td mat-cell *matCellDef="let row"> {{row.Imported}} </td>
    </ng-container>


    <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let row">
            <button mat-icon-button class="no-pad-btn" [matMenuTriggerFor]="cardMenu"
            #menuTrigger="matMenuTrigger">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #cardMenu="matMenu" xPosition="before">
            <span (mouseleave)="menuTrigger.closeMenu()">
                <button mat-menu-item (click)="openEditCreditCardDialog(row);">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                </button>
                <button mat-menu-item (click)="openDeleteDialog(row);"
                    [disabled]="row.Imported === true">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </span>
        </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>