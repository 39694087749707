import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { UserService } from '../../../security/users/services/user.service';
import { addDays, format } from 'date-fns';
import { CreditCardService } from '../../../accounting/credit-cards/credit-card.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-edit-credit-card-dialog',
  templateUrl: './edit-credit-card-dialog.component.html',
  styleUrls: ['./edit-credit-card-dialog.component.scss']
})
export class EditCreditCardDialogComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) cardHolderAuto: MatAutocompleteTrigger;
  filteredUsers: Observable<any[]>;
  formGroup: UntypedFormGroup;
  result = {}
  users: any[];
  creditCard: any;
  loading = false;

  constructor(public dialogRef: MatDialogRef<EditCreditCardDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, private _userService: UserService, private _creditCardService: CreditCardService, private _toastrService: ToastrService) {
    this.buildForm();
    if (this.data.creditCardId !== null && this.data.creditCardId > 0) {
      this.getCreditCard(this.data.creditCardId);
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.formGroup.markAsPristine;
    this.formGroup.markAsUntouched;
  }

  private getCreditCard(creditCardId: number): void {
    this.loading = true
    const query = `$select=Id,CardHolderFirstName,CardHolderLastName,CardHolderId,CreditLine,AccountNumber,IssuedOn,ExpiresOn,Status,Imported&$expand=CardHolder($select=Name)`
    this._creditCardService.getCreditCard(creditCardId, query).subscribe(result => {
      this.creditCard = result;
      this.normalizeDates();
      this.loading = false
      this.initializeForm();
      if (this.creditCard?.CardHolder === null) {
        this.getUsers();
      }
    });
  }

  private normalizeDates(): void {
    const dateFields = ['IssuedOn', 'ExpiresOn']
    dateFields.forEach(d => {
      if (this.creditCard[d] !== null) {
        const dateSplit = this.creditCard[d].split('T')[0].split('-');
        const date = `${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`
        this.creditCard[d] = date;
      }
    })
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      'status': new UntypedFormControl(),
      'creditLine': new UntypedFormControl(),
      'issuedOn': new UntypedFormControl(),
      'expiresOn': new UntypedFormControl(),
      'cardHolder': new UntypedFormControl()
    });
  }

  private initializeForm(): void {
    this.formGroup.controls['status'].setValue(this.creditCard?.Status === 'Active' ? true : false);
    this.formGroup.controls['creditLine'].setValue(this.creditCard?.CreditLine);
    this.formGroup.controls['issuedOn'].setValue(this.creditCard?.IssuedOn !== null ? new Date(this.creditCard?.IssuedOn) : null);
    this.formGroup.controls['expiresOn'].setValue(this.creditCard?.ExpiresOn !== null ? new Date(this.creditCard?.ExpiresOn) : null);
  }

  private loadAutoComplete() {
    this.filteredUsers = this.formGroup.controls['cardHolder'].valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.Name)),
        map(name => (name ? this._filterUsers(name) : this.users.slice())),
      );
  }

  private _filterUsers(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.users.filter(user => user.Name.toLowerCase().includes(filterValue));
  }

  private getUsers() {
    const date = format(addDays(new Date, -30), 'yyyy-MM-dd');
    const query = `$select=Id,Name,Number,Email&$filter=LastWorkedOn gt ${date} and Status eq 'Active'&$orderby=Name`;
    this._userService.getUsers(query).subscribe(result => {
      this.users = result['value'];
      this.loadAutoComplete();
    });
  }

  getUserDisplayName(user: any) {
    return user && user.Name ? user.Name : '';
  }

  onSave(): void {
    const payload = this.getpayload();
    if (Object.keys(payload).length > 0) {
      this.saveChanges(this.data.creditCardId, payload);
    }
  }

  private saveChanges(creditCardId, payload): void {
    this._creditCardService.updateCreditCard(creditCardId, payload).subscribe(result => {
      this._toastrService.success('Credit cards successfully updated!');
      this.dialogRef.close({ 'response': 'success' })
    }, error => {
      this._toastrService.error(`Oops! Unable to update credit card. ${error.error.message}`, 'Error', { timeOut: 5000 })
    })
  }


  private getpayload(): any {
    const payload = {}
    for (const name in this.formGroup.controls) {
      if (this.formGroup.controls[name].touched) {
        const value = this.formGroup.controls[name].value;
        if (name === 'cardHolder') {
          payload['cardHolderId'] = value?.Id;
        }
        else if (name === 'status') {
          payload[name] = value === true ? 'Active' : 'Inactive';
        }
        else if (name === 'issuedOn' || name === 'expiresOn') {
          payload[name] = value !== null ? format(value, 'yyyy-MM-dd') : value
        }
        else {
          payload[name] = value;
        }
      }
    }
    return payload
  }
}
