
export class CompanySetting {
    id: number;
    name: string;
    description: string;
    displayName: string;
    value: string;
    createdBy: string;
    createdByFullName: string;
    lastModifiedBy: string;
    lastModifiedOn: Date;
    lastModifiedByFullName: string;
}


export class DailysSetting {
    isEntryPastDue: boolean;
    entryDueDate: Date;
    entryDueDay: string;
    isApprovalPastDue: boolean;
    approvalDueDate: Date;
    approvalDueDay: string;
    isJhaRequired: boolean;
    isDiaryRequired: boolean;
    isTimecardEntryAllowed: boolean;
    maxHoursPerWeek: number;
    maxHoursPerDay: number;
    lateDaysAllowed: number;
    averageManHourCost: number;
    requireToDateSummary: boolean
}
