import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  apiUrl = environment.apiUrl;
  endpoint = `${this.apiUrl}/CreditCards`;

  constructor(private _httpClient: HttpClient) { }

  getCreditCard(crediCardId: number, query: string): Observable<any> {
    return this._httpClient.get<any>(`${this.endpoint}(${crediCardId})?${query}`);
  }

  getCreditCards(query: string): Observable<any[]> {
    return this._httpClient.get<any[]>(`${this.endpoint}?${query}`);
  }

  updateCreditCard(creditCardId: number, patch: any): Observable<any> {
    return this._httpClient.patch<any>(`${this.endpoint}(${creditCardId})`, patch);
  }

  createCreditCard(creditCard: any): Observable<any> {
    return this._httpClient.post<any>(`${this.endpoint}`, creditCard);
  }

  deleteCreditCard(creditCardId: number): Observable<any> {
    return this._httpClient.delete<any>(`${this.endpoint}(${creditCardId})`);
  }

  importCreditCards(formData: FormData): Observable<any> {
    return this._httpClient.post<any>(`${this.endpoint}/Import`, formData);
  }
}
