import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { DateService } from '../../../shared/services/date.service';
import { TimecardService } from '../services/timecard.service';


@Component({
  selector: 'app-delete-timecard-dialog',
  templateUrl: './delete-timecard-dialog.component.html',
  styleUrls: ['./delete-timecard-dialog.component.scss']
})
export class DeleteTimecardDialogComponent {

  weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  currentWeek: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<DeleteTimecardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private _dateService: DateService,
    private _timecardService: TimecardService,
  ) {
    this.currentWeek = this._dateService.getWeekDates(new Date());
  }

  HanldeDeletion(): void {
    let data$ = this.data
      .slice(2, 9)
      .filter(item => item?.hours > 0)
      .map(item => this._timecardService.deleteTimecard(item.id))

    forkJoin(data$).subscribe(result => {
      console.log(result);
      this.dialogRef.close(true);
    }, error => {
      console.log('Error: ', error);
    });
  }
}
