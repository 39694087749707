import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateService } from '../../services/date.service';

@Component({
  selector: 'app-week-selector',
  templateUrl: './week-selector.component.html',
  styleUrls: ['./week-selector.component.scss']
})
export class WeekSelectorComponent implements OnInit {
  @Input('startDate') startDate: string;
  @Output() weekChangeEvent = new EventEmitter();
  weekDates: string[];
  startDateStr: string = '';
  endDateStr: string = '';
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  constructor(private _dateService: DateService) {
    this.weekDates = JSON.parse(JSON.stringify(this._dateService.getCurrentWeekDatesAsString()));
  }

  ngOnInit() {
    if (this.startDate !== undefined && this.startDate !== null && this.startDate !== '') {
     this.weekDates =  JSON.parse(JSON.stringify(this._dateService.getWeekDates(new Date(this.startDate))));
    }
    this.setWeek();
  }

  updateWeek(weeks: number): void {
    if (weeks === -1) {
      this.weekDates =  JSON.parse(JSON.stringify(this._dateService.getPreviousWeekDates(this.weekDates)));
    } else {
      this.weekDates = JSON.parse(JSON.stringify(this._dateService.getNextWeekDates(this.weekDates)));
    }
    this.setWeek();
    this.weekChangeEvent.emit(this.weekDates);
  }

  private setWeek(): void {
    this.startDateStr = this.weekDates[0];
    this.endDateStr =  this.weekDates[6];
    this.range.controls['start'].setValue(new Date(this.weekDates[0]));
    this.range.controls['end'].setValue(new Date(this.weekDates[6]));
  }

  onDateChangeEvent(range: any): void {
    if (range.value.start != null) {
      this.weekDates = this._dateService.getWeekDates(new Date(range.value.start));
      this.setWeek();
      this.weekChangeEvent.emit(this.weekDates);
    }
  }
}
