<h2 mat-dialog-title>Edit Credit Card</h2>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div fxLayoutAlign="start center" class="legend-items-container">
            <div class="legend-items">
                @if(creditCard?.CardHolder != null) {
                <div class="legend-item ng-star-inserted" tabindex="-1">
                    <div class="item-color" style="border-left-color: rgb(167, 165, 165)"></div>
                    <div class="item-label">Cardholder</div>
                    <div class="item-value ng-star-inserted"> {{creditCard?.CardHolder?.Name | slice:0:20}}</div>
                </div>
                }
                <div class="legend-item ng-star-inserted" tabindex="-1">
                    <div class="item-color" style="border-left-color: rgb(167, 165, 165)"></div>
                    <div class="item-label">Account Name</div>
                    <div class="item-value ng-star-inserted">{{creditCard?.CardHolderFirstName | slice:0:20}}
                        {{creditCard?.CardHolderLastName}} </div>
                </div>
                <div class="legend-item ng-star-inserted" tabindex="-1">
                    <div class="item-color" style="border-left-color: rgb(167, 165, 165)"></div>
                    <div class="item-label">Account No.</div>
                    <div class="item-value ng-star-inserted">***{{creditCard?.AccountNumber}} </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <br>
        @if(creditCard?.CardHolder == null) {
        <div fxLayout="row">
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>CardHolder</mat-label>
                <input matInput formControlName="cardHolder" aria-label="User" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="getUserDisplayName">
                    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
                        <span>{{user.Name}}</span>
                    </mat-option>
                </mat-autocomplete>
                @if(formGroup.controls['cardHolder'].errors?.['required']) {
                <mat-error>CardHolder is required</mat-error>
                }
            </mat-form-field>
        </div>
        }
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Issued On(optional)</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="issuedOn">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Expires On(optional)</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="expiresOn">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="49">
                <mat-label>Credit Line(optional)</mat-label>
                <input matInput type="number" formControlName="creditLine">
            </mat-form-field>
            <div>
                <mat-slide-toggle formControlName="status">Active</mat-slide-toggle>
            </div>
        </div>

        <br>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" [mat-dialog-close]="null">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!formGroup.valid || !formGroup.dirty"
        (click)="onSave()">Save</button>
</mat-dialog-actions>