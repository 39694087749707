import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { CreditCardService } from '../../../accounting/credit-cards/credit-card.service';
import { UserService } from '../../../security/users/services/user.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CreateCreditCardDialogComponent } from '../create-credit-card-dialog/create-credit-card-dialog.component';
import { EditCreditCardDialogComponent } from '../edit-credit-card-dialog/edit-credit-card-dialog.component';

@Component({
  selector: 'app-credit-cards-list',
  templateUrl: './credit-cards-list.component.html',
  styleUrls: ['./credit-cards-list.component.scss']
})
export class CreditCardsListComponent implements OnInit {

  @Input('creditCards') creditCards: any[];
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchText: string;
  dataSource = new MatTableDataSource<any[]>();
  displayedColumns: string[] = ['AccountNumber', 'CardHolderFirstName', 'CreditLine', 'Status', 'CardHolder', 'Imported', 'Action'];
  fetchingData = false;
  isExporting = false;
  user: any;
  users: any[] = [];

  constructor(public dialog: MatDialog, private _toastrService: ToastrService, private _creditCardService: CreditCardService, private _userService: UserService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.dataSource.data = this.creditCards;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }


  openNewCreditCardDialog(): void {
    const data = this.user;
    const dialogRef = this.dialog.open(CreateCreditCardDialogComponent, {
      width: '600px',
      data: (data)
    });

    dialogRef.afterClosed().subscribe(formData => {
      if (formData !== null) {
        this.createCreditCard(formData);
      }
    });
  }

  private createCreditCard(formData): void {
    formData['cardholderId'] = this.user.Id;
    this._creditCardService.createCreditCard(formData).subscribe(result => {
      this.creditCards.push(result);
      this.dataSource.data = this.creditCards;
      this._toastrService.success('Credit Card successfully added!')
    }, error => {
      this._toastrService.error(`Oops! Unable to add credit card. ${error.error.message}`, 'Error', { timeOut: 5000 })
    })
  }

  openEditCreditCardDialog(creditCard: any) {
    const data = {
      'creditCard': creditCard,
      'users': this.users
    };

    if (this.users.length === 0) {
      this._userService.getUsers(`$select=Id,Name,FirstName,LastName&$filter=Status eq 'Active'`).subscribe(result => {
        this.users = result['value'];
        data.users = this.users;
        this.openDialog(data);
      })
    }
    else {
      this.openDialog(data);
    }
  }

  private openDialog(data: any) {
    const dialogRef = this.dialog.open(EditCreditCardDialogComponent, {
      width: '450px',
      data: (data)
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result !== null) {
        this.editCreditCard(data.creditCard, result.value);
      }
    });
  }

  private editCreditCard(creditCard: any, formData: any): void {
    this._creditCardService.updateCreditCard(creditCard.Id, formData).subscribe( result =>  {
      const index = this.creditCards.findIndex(c => c.Id === creditCard.Id);
      this.creditCards[index].CardHolder = formData.CardHolder;
      this.creditCards[index].CreditLine = formData.CreditLine;
      this.creditCards[index].IssuedOn = formData.IssuedOn;
      this.creditCards[index].ExpiresOn = formData.ExpiresOn;
      this.dataSource.data = this.creditCards;
    })
  }
  
  openDeleteDialog(creditCard: any): void {
    const data = {
      'title': 'Please Confirm',
      'message': `Are sure you want to delete this credit card?`,
      'cancelText': 'CANCEL',
      'confirmText': 'DELETE'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: (data)
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.deleteCreditCard(creditCard);
      }
    });
  }

  private deleteCreditCard(creditCard: any): void {

    this._creditCardService.deleteCreditCard(creditCard.Id).subscribe(result => {
      this._toastrService.success(`Successfully delete credit card!`, 'Success', { timeOut: 3000 })
      const index = this.creditCards.findIndex(c => c.Id === creditCard.Id);
      this.creditCards.splice(index, 1);
      this.dataSource.data = this.creditCards;
    }, error => {
      this._toastrService.error(`Oops! Unable to delete credit card. ${error.error.message}`, 'Error', { timeOut: 5000 })
    })
  }

}
